//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { assetURL } from '@/plugins/utilities'
import Icon from '@/components/Icon.vue'
export default {
  components: {
    Icon,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isStatic: {
      type: Boolean,
      default: false,
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
  methods: { assetURL },
}
